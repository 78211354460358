<template>
  <base-section
    id="about-our-product"
    class="pb-0"
  >
    <base-section-heading
      title="지능형 자동화 솔루션"
    />
    <v-container>
      <v-row>
        <v-col
          v-for="card in cards"
          :key="card.title"
          cols="12"
          md="4"
        >
          <base-info-card
            v-bind="card"
            align="center"
          />
        </v-col>
      </v-row>
      <br>
      <br>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAboutOurProduct',

    data: () => ({
      classConf: '',
      cards: [
        {
          title: '스마트 팩토리 솔루션',
          subtitle: '공장 자동화',
          text: '',
          callout: '',
          icon: 'mdi-factory',
        },
        {
          title: '스마트 웨어하우스',
          subtitle: '물류 자동화',
          text: '',
          callout: '',
          icon: 'mdi-warehouse',
        },
        {
          title: 'IT 시스템 통합',
          subtitle: 'ERP, WMS, WCS, MES',
          text: '',
          callout: '',
          icon: 'mdi-sitemap',
        },
      ],
    }),
  }
</script>
